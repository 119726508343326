module.exports = {
  /**
   * Async/sync pipe, functions can be sync or async
   * @param fns
   * @returns {function(*=): (*|Promise<any>)}
   */
  apipe: (...fns) => state => fns.reduce((acc, fn) => acc.then(fn), Promise.resolve(state)),
  /**
   * @deprecated
   * use instead Ramda.pipe
   *  do not delete this function
   *  it is used by:
   *  widgets/become-writer/index.jsx
   *  widgets/order-form/request-functions/response-handlers.js
   *  widgets/order-form/request-functions/response-modifires.js
   *  widgets/order-form/event-handlers/index.js
   *  widgets/_form-core/date-functions.js
   *  widgets/get-price/index.jsx:
   *  widgets/restore-password-form/form-2/index.jsx:
   *  widgets/restore-password-form/form-1/index.jsx:
   *  widgets/_primitives/orders-in-disciplines/index.jsx:
   *  widgets/_primitives/email-hint/index.jsx:
   *  widgets/_primitives/rating-stars/index.jsx:
   *  widgets/_primitives/writer-statistic/index.jsx:
   *  widgets/sign-in-form/index.jsx:
   * @param fns
   * @returns {function(*=): (*)}
   */
  pipe: (...fns) => state => fns.reduce((acc, fn) => fn(acc), state),
};
