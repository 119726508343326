export const SET_AUTH = 'SET_AUTH';
export const SET_SERVICE_PAGE = 'SET_SERVICE_PAGE';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_REQUESTED_WRITER = 'SET_REQUESTED_WRITER';
export const REMOVE_REQUESTED_WRITER = 'REMOVE_REQUESTED_WRITER';
export const SET_EMAIL = 'SET_EMAIL';

export const setAuth = val => ({
  type: SET_AUTH,
  payload: val,
});

export const setServicePage = val => ({
  type: SET_SERVICE_PAGE,
  payload: val,
});

export const setCustomer = val => ({
  type: SET_CUSTOMER,
  payload: val,
});

export const setRequestedWriter = val => ({
  type: SET_REQUESTED_WRITER,
  payload: val,
});

export const removeRequestedWriter = () => ({
  type: REMOVE_REQUESTED_WRITER,
});

export const setEmail = val => ({
  type: SET_EMAIL,
  payload: val,
});

const reducer = (state, action) => {
  switch (action.type) {
    case SET_AUTH: {
      return {
        ...state,
        auth: action.payload,
      };
    }

    case SET_SERVICE_PAGE: {
      return {
        ...state,
        isServicePage: action.payload,
      };
    }

    case SET_CUSTOMER: {
      return {
        ...state,
        customer: action.payload,
      };
    }

    case SET_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }

    case SET_REQUESTED_WRITER: {
      return {
        ...state,
        requestedWriter: action.payload,
      };
    }

    case REMOVE_REQUESTED_WRITER: {
      return {
        ...state,
        requestedWriter: {
          name: '',
          slug: '',
        },
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
