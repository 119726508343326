/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import useApi from '@front/hooks/useApi';
import useEffectAfterMount from '@front/hooks/useEffectAfterMount';
import { api, authDefault } from '@front/gatsby-utils/api';

import reducer, {
  setAuth,
  setCustomer,
  setRequestedWriter,
  removeRequestedWriter,
  setEmail,
} from './ducks';

const defaultState = {
  auth: undefined,
  email: '',
  isServicePage: false,
  customer: {
    message: '',
    msg: '',
    redirect: '',
    user_avatar: '',
    user_balance: '',
    user_email: '',
    user_links: undefined,
    user_login: '',
    user_role: '',
  },
  requestedWriter: {
    name: '',
    slug: '',
  },
};

export const ProviderContext = createContext(defaultState);
export { setRequestedWriter, removeRequestedWriter, setEmail };

const Provider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { data = defaultData } = useApi(api.userAuth);

  const dataSerialized = JSON.stringify(data);

  useEffectAfterMount(() => {
    const { auth, ...customer } = data;

    dispatch(setAuth(auth));
    dispatch(setCustomer(customer));
  }, [dataSerialized]);

  return (
    <ProviderContext.Provider value={{ ...state, dispatch }}>{children}</ProviderContext.Provider>
  );
};

Provider.propTypes = {
  defaultData: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

Provider.defaultProps = {
  defaultData: authDefault,
};

export default Provider;
