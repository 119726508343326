import { useEffect, useRef } from 'react';

/**
 * @param {function} cb
 * @param {array} deps
 * @returns {void}
 */
const useEffectAfterMount = (cb, deps) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      cb();
    }

    mounted.current = true;
  }, deps);
};

export default useEffectAfterMount;
