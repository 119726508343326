/**
 * @typedef {Object} Breakpoints
 * @property {string} xs
 * @property {string} sm
 * @property {string} md
 * @property {string} lg
 * @property {string} xl
 */

/**
 * @see https://material-ui.com/customization/breakpoints/
 * @type {Breakpoints}
 */
export const breakpoints = ['0px', '600px', '960px', '1280px', '1920px'];

/* eslint-disable prefer-destructuring */
breakpoints.xs = breakpoints[0]; // 0px
breakpoints.sm = breakpoints[1]; // 600px
breakpoints.md = breakpoints[2]; // 960px
breakpoints.lg = breakpoints[3]; // 1280px
breakpoints.xl = breakpoints[4]; // 1920px

/**
 * @typedef {array} FontSizes
 * @property {string} body
 * @property {string} h4
 * @property {string} h3
 * @property {string} h2
 * @property {string} h1
 */

/**
 *
 * @type {FontSizes}
 */
export const fontSizes = [
  '14px', // 0
  '16px', // 1
  '18px', // 2
  '20px', // 3
  '24px', // 4
  '32px', // 5
  '36px', // 6
  '48px', // 7
];

/**
 * calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
 * @param {number} min
 * @param {number} max
 * @returns {string}
 */
const calcFluid = (min, max) =>
  `calc(${min}px + ${max - min} * ((100vw - 300px) / (1920 - 300)))`;

fontSizes.body = '1rem';
fontSizes.caption = '12px';
fontSizes.h4 = calcFluid(16, 18);
fontSizes.h3 = '.9375rem';
fontSizes.h3m = fontSizes[2];
fontSizes.h2 = fontSizes[4];
fontSizes.h2m = fontSizes[3];
fontSizes.h1 = fontSizes[6];
fontSizes.h1m = fontSizes[4];

/**
 * Custom theme
 * @see https://rebassjs.org/theming/
 * @see https://rebassjs.org/variants/
 */

const body = {
  m: 0,
  mb: 3,
  fontSize: [fontSizes[1], null, null, 'body'],
  lineHeight: '160%',
  color: 'basic.2',
};

const h1 = {
  fontSize: ['h1m', null, null, 'h1'],
  lineHeight: [fontSizes[6], null, null, fontSizes[7]],
  fontWeight: '400',
  my: 3,
};

const h2 = {
  color: 'basic.2',
  fontSize: ['h2m', null, null, 'h2'],
  lineHeight: ['h2', null, null, fontSizes[7]],
  fontWeight: 400,
};

const h3 = {
  color: 'basic.2',
  fontSize: ['h3m', null, null, 'h3'],
  lineHeight: ['h3m', null, null, 'h3'],
  fontWeight: 600,
};

const h4 = {
  color: 'basic.2',
  fontSize: 'h4',
  lineHeight: 'h4',
  my: 3,
};

const link = {
  color: '#51adb7',
  textDecoration: 'underline',

  '&:hover': {
    color: '#51adb7',
    textDecoration: 'underline',
  },

  '&.activeLink': {
    pointerEvents: 'none',
    textDecoration: 'underline',
  },
};

const ul = {
  p: 0,
  m: 0,
  listStyle: 'none',

  '& > li': {
    position: 'relative',
    mb: 3,
    pl: 4,
  },

  '& > li::before': {
    position: 'absolute',
    top: '10px',
    left: '10px',
    content: '""',
    display: 'block',
    width: '6px',
    height: '6px',
    borderRadius: 0,
    flexShrink: 0,
    backgroundColor: 'basic.1',
  },
};

const ol = {
  p: 0,
  m: 0,
  listStyle: 'none',
  counterReset: 'listCounter',

  '& > li': {
    position: 'relative',
    mb: 3,
    pl: 4,
    counterIncrement: 'listCounter',
  },

  '& > li::before': {
    position: 'absolute',
    left: 0,
    content: 'counter(listCounter)"."',
    display: 'block',
    width: '32px',
    flexShrink: 0,
    color: 'basic.4',
  },
};

const theme = {
  breakpoints,
  fontSizes,
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Open Sans, sans-serif',
  },
  lineHeights: {
    heading: '24px',
    h1: calcFluid(28, 56),
    h2: calcFluid(28, 42),
    h3: calcFluid(24, 28),
    h4: calcFluid(22, 24),
    body: calcFluid(20, 24),
  },
  fontWeights: {
    body: '400',
    heading: '700',
    bold: '700',
  },
  colors: {
    basic: [
        '#FFFFFF', // 0
        '#31aae2', // 1
        '#313131', // 2
        '#c1c1c1', // 3
        '#000000', // 4
        '#1A1A1A'  // 5
        ],
    primary: [
        '#969595', // 0
        '#415458', // 1
        '#f9f9f9'  // 2
      ],
    secondary: [
        '#f0f0f0', // 0
        '#636363', // 1
        '#15b0bf', // 2
        '#f1f1f1'  // 3
      ],
    status: ['#0abf53', '#ffeb3b', '#f44336'],
    ok: '#0abf53',
    warn: '#ffeb3b',
    error: '#f44336',
  },
  space: [0, 4, 8, 16, 32, 64],
  radii: {
    small: '3px',
    medium: '8px',
    large: '16px',
    circle: '50%',
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, .16)',
    medium: '0 6px 16px rgba(0, 0, 0, 0.16)',
    large: '0 24px 48px rgba(0, 0, 0, .16)',
  },
  variants: {
    link,
    headerLink: {
      textDecoration: 'none',
      color: 'basic.0',

      '&.activeLink, &:hover, &:active': {
        textDecoration: 'underline',
        color: 'basic.0',
      },
    },
    headerLinkDark: {
      textDecoration: 'none',
      color: 'basic.2',

      '&.activeLink, &:hover, &:active': {
        textDecoration: 'underline',
      },
    },
    policyLink: {
      display: 'block',
      mb: '6px',
      color: '#95bdc6',
      textDecoration: 'none',
      fontSize: '16px',

      '&:hover': {
        color: 'basic.0',
      },

      '&.activeLink': {
        pointerEvents: 'none',
        textDecoration: 'underline',
      },
    },
    footerLink: {
      display: 'block',
      mb: '6px',
      color: 'hsla(0,0%,100%,.5)',
      textDecoration: 'none',
      fontSize: '16px',

      '&:hover': {
        color: 'basic.0',
      },

      '&.activeLink': {
        pointerEvents: 'none',
        textDecoration: 'underline',
      },
    },
    ul,
    ol,
    content: {
      p: body,
      h1,
      h2,
      h3: {
        fontSize: ['h3m', null, null, '1.3rem'],
        fontWeight: '400',
        pb: 2,
      },
      a: link,
      ol,
      ul,
    },
    card: {
      p: 3,
      boxShadow: 'small',
    },
    // https://www.youtube.com/watch?v=owGykVbfgUE
    oldSpice: {
      width: '100%',
      table: {
        borderCollapse: 'collapse',
        mx: 'auto',
      },
      td: {
        border: '1px solid #efefef',
        px: 3,
        py: 2,
        textAlign: 'center',
      },
      'thead td:nth-of-type(1)': {
        textAlign: 'left',
      },
      'tbody td:nth-of-type(1)': {
        textAlign: 'left',
      },
      'tbody td:nth-of-type(2)': {
        color: 'error',
      },
      'tbody td:nth-of-type(3)': {
        color: 'ok',
      },
      thead: {
        fontWeight: 'bold',
      },
    },
  },
  text: {
    body,
    h1,
    h2,
    h3,
    h4,
  },
  ul: {
    mark: {
      '& > li:before': {
        backgroundColor: 'red',
      },
    },
  },
  buttons: {
    primary: {
      fontSize: 1,
      color: 'basic.0',
      fontWeight: 'bold',
      bg: 'transparent',
      borderRadius: 0,
      border: '3px solid #fff',
      textAlign: 'center',
      cursor: 'pointer',

      '&:hover, &:focus': {
        bg: 'hsla(0,0%,100%,.85)',
        color: 'basic.4',
        outline: 0,
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    secondary: {
      fontSize: 1,
      color: 'secondary.2',
      fontWeight: 'normal',
      bg: 'transparent',
      borderRadius: 'small',
      border: '1px solid #73d0d9',
      textAlign: 'center',
      cursor: 'pointer',
      transition: '100ms all ease-in-out',

      '&:hover, &:focus': {
        bg: '#6ac6cf',
        color: 'basic.0',
        outline: 0,
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    submit: {
      fontSize: 1,
      color: 'basic.0',
      fontWeight: 'normal',
      bg: 'basic.1',
      padding: '12px 30px',
      borderRadius: 0,
      border: '1px solid #73d0d9',
      textAlign: 'center',
      cursor: 'pointer',
      transition: '100ms all ease-in-out',

      '&:hover, &:focus': {
        bg: '#30b5ec',
        color: 'basic.0',
        outline: 0,
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
  forms: {
    label: {
      pb: 2,
    },
    input: {
      p: 2,
      color: 'basic.5',
      backgroundColor: 'basic.0',
      borderColor: 'basic.2',
      outline: 0,

      '&::placeholder': {
        color: 'basic.4',
      },

      '&:focus': {
        border: '1px solid #31aae2',
        boxShadow:
          'inset 0 1px 1px rgba(0,0,0,.075), 0 0 3px rgba(49,170,226,.8)',
      },

      '&:disabled': {
        position: 'relative',
        zIndex: 1,
        backgroundColor: 'basic.2',
        borderColor: 'basic.3',
      },

      '&[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      '&.error': {
        borderColor: 'error',
      },
    },
    select: {
      p: 2,
      color: 'basic.2',
      backgroundColor: 'basic.0',
      borderColor: 'basic.2',
      outline: 0,

      '& + svg': {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '8px',
        right: '6px',
        stroke: '#e0e0e0',
      },

      '&::placeholder': {
        color: 'basic.4',
      },

      '&:disabled': {
        backgroundColor: 'basic.2',
        borderColor: 'basic.3',
      },

      '&:focus': {
        borderColor: 'basic.3',
        color: 'basic.2',
      },

      '&::-ms-expand': {
        display: 'none',
      },

      '&.error': {
        borderColor: 'error',
      },
    },
    checkbox: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, .17)',
      boxShadow:
        '0 1px 0 0 rgba(255, 255, 255, .19) inset, 2px 2px 0 0 rgba(0, 0, 0, .1)',
      bg: '#fff',
      outline: 0,
      width: '20px',
      height: '20px',

      borderRadius: 'small',
      '& svg': {
        stroke: '#e0e0e0',
        strokeWidth: 3,
      },
      '&:hover': {
        cursor: 'pointer',
        borderColor: '#51adb7',

        svg: {
          stroke: '#51adb7',
        },
      },
      'input:focus ~ &': {
        borderColor: '#51adb7',

        svg: {
          stroke: '#51adb7',
        },
      },
      'input:active ~ &': {
        borderColor: '#51adb7',

        svg: {
          stroke: '#51adb7',
        },
      },
      'input:disabled:active ~ &': {
        opacity: 0.5,
      },
      'input:disabled ~ &': {
        opacity: 0.5,

        '&:hover': {
          cursor: 'not-allowed',
        },
        '& svg': {
          stroke: '#e0e0e0',
        },
      },

      '&.error': {
        borderColor: 'error',

        svg: {
          stroke: 'error',
        },
      },
    },
    radio: {},
    field: {},
    textarea: {},
  },
  'bw-icons': {
    calendar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '39px',
      height: '39px',
      backgroundColor: 'transparent',
      position: 'absolute',
      top: '1px',
      right: '1px',
      bottom: '1px',
      p: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: '24px',
      borderBottomRightRadius: '24px',
      borderBottomLeftRadius: 0,
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderLeftColor: 'fieldBorder',
      transition: 'background-color 0.3s',
      outline: 0,

      '& svg': {
        stroke: '#406e79',
        transition: 'stroke 0.3s',
      },

      'input:disabled ~ & svg': {
        opacity: 0.5,
      },

      'input:disabled ~ &:hover': {
        cursor: 'not-allowed',
      },
      '&:hover': {
        cursor: 'pointer',

        svg: {
          stroke: '#51adb7',
        },
      },
      '&:focus': {
        svg: {
          stroke: '#51adb7',
        },
      },
      '&:active': {
        svg: {
          stroke: '#51adb7',
        },
      },
    },
    plus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '38px',
      height: '38px',
      position: 'absolute',
      top: '1px',
      right: '1px',
      bottom: '1px',
      p: 0,
      backgroundColor: '#f2f2f2',
      borderRadius: 0,
      transition: 'background-color 0.3s',
      outline: 0,

      '& svg': {
        width: '18px',
        height: '18px',
        stroke: '#406e79',
        transition: 'stroke 0.3s',
      },
      'input:disabled ~ & svg': {
        opacity: 0.5,
      },
      'input:disabled ~ &:hover': {
        backgroundColor: 'inherit',
        cursor: 'not-allowed',
      },
      '&:hover': {
        bg: '#e0e0e0',
        cursor: 'pointer',
      },
      '&:focus': {
        bg: '#e0e0e0',
      },
      '&:active': {
        bg: '#e0e0e0',
      },
    },
    minus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '38px',
      height: '38px',
      position: 'absolute',
      top: '1px',
      left: '1px',
      bottom: '1px',
      p: 0,
      backgroundColor: '#f2f2f2',
      borderRadius: 0,
      transition: 'background-color 0.3s',
      outline: 0,

      '& svg': {
        width: '18px',
        height: '18px',
        stroke: '#406e79',
        transition: 'stroke 0.3s',
      },
      'input:disabled ~ & svg': {
        opacity: 0.5,
      },
      'input:disabled ~ &:hover': {
        backgroundColor: 'inherit',
        cursor: 'not-allowed',
      },
      '&:hover': {
        bg: '#e0e0e0',
        cursor: 'pointer',
      },
      '&:focus': {
        bg: '#e0e0e0',
      },
      '&:active': {
        bg: '#e0e0e0',
      },
    },
  },
  alerts: {
    'field-error': {
      fontSize: 0,
      color: 'error',
    },
    'form-error': {
      mb: 3,
      py: 2,
      px: 3,
      fontSize: 1,
      textAlign: 'center',
      bg: 'error',
      color: '#fff',
    },
  },
};

export default theme;
