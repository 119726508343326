/**
 * Implement Gatsby's Browser APIs in this file.
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '@front/ds/themes/essayseek.com';
import ModalProvider from '@front/hooks/useModal/modalProvider';
import Provider from 'Components/provider';
import { doPpcVisit } from '@front/gatsby-utils/nextapi';
import '@fontsource/open-sans';
import '@fontsource/open-sans/700.css';
import 'Static/slick.css';
import 'react-hint/css/index.css';

import { createRoot } from 'react-dom/client';

export const replaceHydrateFunction = () => (element, container) => {
  const root = createRoot(container);
  root.render(element);
};

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Provider>
      <ModalProvider>{element}</ModalProvider>
    </Provider>
  </ThemeProvider>
);

/**
 * @see https://github.com/gatsbyjs/gatsby/issues/23676
 * @returns {Promise<void>}
 */
export const onClientEntry = () => {
  if (typeof IntersectionObserver === 'undefined') {
    return import('intersection-observer');
  }

  doPpcVisit({
    referrer: document.referrer,
    url: document.location.href,
    key: 'subid',
  }).then(({ status }) => status);

  return Promise.resolve();
};
