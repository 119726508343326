export default {
  archive: {
    id: '1',
    policy_internal_title: 'Archive Policy Title',
    policy_txt: 'loading...',
    policy_type: '0',
    active_dt: '2017-01-17 00:00:00',
    actualPolicy: {
      id: '2',
      policy_internal_title: 'Active Policy Title',
      policy_txt: 'loading...',
      policy_type: '0',
      active_dt: '2019-01-17 00:00:00',
    },
    allArchivePolicy: [
      {
        id: '0',
        policy_internal_title: 'Archive Policy Title 2',
        policy_text: 'loading...',
        policy_type: '0',
        active_dt: '2017-01-17 00:00:00',
      },
    ],
  },
};
