export default [
  {
    order: {
      id: 152787265,
      title_private: 'N',
      title: 'Loading...',
      paper_type: 'Loading...',
      pages_qty: '2',
      status: '100',
      status_title: 'Completed',
      rating_dt_date: 'Loading...',
      rating_dt_time: '10:53 PM',
      rating_dt_timeago: 'loading...',
      rating_dt_in2days: 'Y',
      rating_writer: '10',
      rating_writer_comment: 'Loading...',
      rating_customer: '10',
      rating_customer_comment: 'Loading...',
      discipline_title: 'loading...',
    },
    writer: {
      pen_name_slug: 'writer',
      pen_name: 'loading..',
      avatar: '',
      rating: '9.87',
      writer_status: '1100',
      orders_finished_qty: '1',
      warning_qty: '0',
      award_qty: '1',
      auth: {
        online_status: 'online',
      },
    },
  },
];
