/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import ModalContext from './modalContext';
import ModalRoot from './modalRoot';

const ModalProvider = ({ container, children }) => {
  const [modals, setModals] = useState({});
  const showModal = useCallback((key, modal) => setModals(mls => ({ ...mls, [key]: modal })), []);
  const hideModal = useCallback(
    key =>
      setModals(mls => {
        const newModals = { ...mls };
        delete newModals[key];
        return newModals;
      }),
    [],
  );

  const contextValue = useMemo(() => ({ showModal, hideModal }), []);

  return (
    <ModalContext.Provider value={contextValue}>
      <Fragment>
        {children}
        <ModalRoot
          modals={modals}
          container={container}
        />
      </Fragment>
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  container: PropTypes.element,
  children: PropTypes.node.isRequired,
};

ModalProvider.defaultProps = {
  container: undefined,
};

export default ModalProvider;
