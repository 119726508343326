import axios from 'axios';
import { apipe } from '../compose';

const PPC_STATUS_NEW = 'new';
const PPC_STATUS_ERROR = 'error';
const PPC_STATUS_EMPTY = 'empty';

export const find = path => obj =>
  path.split('.').reduce((acc, key) => acc[key], obj);
export const when = (condition, f) => x => (condition(x) ? f(x) : x);
export const is = (...keys) => state =>
  keys.reduce((acc, key) => acc && find(key)(state), true);

/**
 * @sideeffect {Function} encodeURIComponent
 * @param {Object} obj
 * @returns {String} searchParams
 */
export function toUrlEncoded(obj) {
  return Object.keys(obj).reduce((searchStr, key, i, arr) => {
    const delimiter = arr.length - 1 === i || !obj[arr[i + 1]] ? '' : '&';

    return obj[key]
      ? searchStr.concat(
          `${encodeURIComponent(key)}=${encodeURIComponent(
            obj[key],
          )}${delimiter}`,
        )
      : searchStr;
  }, '');
}

/**
 * @param {State} state
 */
export function hasQueryParam(state) {
  const { key } = state;

  return {
    ...state,
    isParamExists: new URLSearchParams(window.location.search).has(key),
  };
}

/**
 * @param {State} state
 */
export function rmQueryParam(state) {
  const { key } = state;
  const p = new URLSearchParams(window.location.search);
  p.delete(key);

  const search =
    p.toString().length > 0
      ? `${window.location.pathname}?${p.toString()}`
      : window.location.pathname;

  window.history.replaceState(null, '', search);

  return state;
}

/**
 * @param {State} state
 */
export function ppcVisit(state) {
  const { url, referrer, domain } = state;

  return axios({
    url: `${domain ? `https://${domain}` : ''}/api/en/1/services/ppc-visit`,
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;',
    },
    data: toUrlEncoded({
      url,
      referer: referrer,
    }),
  })
    .then(({ data }) => ({
      ...state,
      status: data?.body?.status || PPC_STATUS_NEW,
    }))
    .catch(() => ({ ...state, status: PPC_STATUS_ERROR }));
}

/**
 * @typedef {Object} State
 * @property {string} url
 * @property {string} referrer
 * @property {string} key
 * @property {string|undefined} domain
 * @property {'new'|'error'|'empty'} status
 * @property {boolean} isParamExists
 *
 * @param {Object} params
 * @param {string} params.url - current url
 * @param {string} params.referrer
 * @param {string} params.key - query param
 * @param {string} [params.domain] - domain
 * @returns {State}
 */
export function doPpcVisit({ url, referrer, key, domain }) {
  return apipe(
    when(is('key'), hasQueryParam),
    when(is('isParamExists', 'url'), ppcVisit),
    when(is('key'), rmQueryParam),
  )({
    url,
    referrer,
    key,
    domain,
    status: PPC_STATUS_EMPTY,
    isParamExists: false,
  });
}
