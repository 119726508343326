import React, { Fragment, memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const ModalRenderer = memo(({ component, ...rest }) => component(rest));

ModalRenderer.displayName = 'ModalRenderer';

ModalRenderer.propTypes = {
  component: PropTypes.func.isRequired,
};

const ModalRoot = memo(({ modals, container: Container = Fragment }) => {
  const [mountNode, setMountNode] = useState(undefined);

  useEffect(() => setMountNode(document.body), []);

  const mls = Object.keys(modals).map(key => (
    <ModalRenderer
      key={key}
      component={modals[key]}
    />
  ));

  return mountNode ? createPortal(<Container>{mls}</Container>, document.body) : null;
});

ModalRoot.displayName = 'ModalRoot';

ModalRoot.propTypes = {
  modals: PropTypes.shape({}).isRequired,
  container: PropTypes.node,
};

ModalRoot.defaultProps = {
  container: undefined,
};

export default ModalRoot;
