/**
 * @TODO simplify
 */
import { FORM_ERROR } from 'final-form';

export const api = {
  userPolicy: '/auth/aj_check_user_policy.html',
  userRole: '/auth/aj_role_required2.html',
  userAuth: '/auth/aj_current2.html',
  satOrder: '/bricks/sat_short_order_form.html',
  login: '/auth/aj_login2.html',
  stats: '/bricks/aj_export_statistics.html?json=1',
  widgetStats: '/api/en/1/widgets/statistics',
  testimonials: '/bricks/aj_testimonials_data.html?format=json',
  testimonialsWidget: '/api/en/1/widgets/testimonials-rotator',
  orders: '/bricks/aj_latest_orders.html',
  fvt: '/bricks/aj_fvt.html',
  password1: '/auth/aj_remind_form1.html',
  password2: '/auth/aj_remind_form2.html',
  posts: '/blog/wp-json/wp/v2/posts/',
  data: '/bricks/es_var.html',
  policy: '/bricks/aj_archive_policy.html',
  writers: '/bricks/aj_topwriters.html',
  writer: '/bricks/aj_writer_profile2.html',
  captchaImg: '/w3t_img/captcha/captcha2.php',
  captchaSound: '/w3t_img/captcha/play2.php',
  archive: '/bricks/aj_archive_policy.html?id=',
  approxPrices: '/api/en/1/widgets/dictionaries/deadline_level,price',
  becomeWriter: '/bricks/writer_registration.html',
  discountPolicy: '/api/en/1/widgets/variables/content_discount_policy',
  getVariable: key => `/api/en/1/widgets/variables/${key}`,
};

export const variables = {
  moneyBackGuarantee: 'content_money_back_guarantee',
  refundPolicy: 'content_refund_policy',
  confidentialityPolicy: 'content_confidentiality_policy',
  cookiePolicy: 'content_cookie_policy',
  privacyPolicy: 'content_privacy_policy',
  discountPolicy: 'content_discount_policy',
  terms: 'content_terms',
  ratingAndAwards: 'content_rating_and_awards',
  faq: 'content_faq_for_customers',
};

export const policiesPageMap = {
  content_refund_policy: '/refund-policy',
  content_confidentiality_policy: '/confidentiality-policy',
  content_cookie_policy: '/cookie-policy',
  content_privacy_policy: '/privacy-policy',
  content_discount_policy: '/discount-policy',
  content_terms: '/terms-and-conditions',
  content_rating_and_awards: '/rating-and-awards',
};

export const req = {
  USER_POLICY_TAKEN: 1,
  USER_POLICY_NOT_TAKEN: 0,
};

export const res = {
  /**
   * Api.userPolicy
   */
  USER_EXIST: 'exist',
  USER_NOT_EXIST: 'no-exist',
  USER_CUSTOMER: 'customer',
  POLICY_TAKEN: 'exist',
  POLICY_NOT_TAKEN: 'no_exist',

  /**
   * Api.userRole
   */
  USER_ROLE_CORRECT: 'exist',
  USER_ROLE_NEW: 'no_exist',
  USER_ROLE_INVALID_REQUEST: 'empty',
  USER_ROLE_OTHER: 'other_role',

  /**
   * Api.authStatus
   */
  USER_AUTHORIZED: 1,
  USER_UNAUTHORIZED: 0,
};

export const parameters = {
  moneyBackGuarantee: { key: 'content_money_back_guarantee' },
  refundPolicy: { key: 'content_refund_policy' },
  confidentialityPolicy: { key: 'content_confidentiality_policy' },
  cookiePolicy: { key: 'content_cookie_policy' },
  privacyPolicy: { key: 'content_privacy_policy' },
  terms: { key: 'content_terms' },
  ratingAndAwards: { key: 'content_rating_and_awards' },
  faq: { key: 'content_faq_for_customers' },
  userPolicy(val) {
    return {
      user_login: val,
    };
  },
};

export const handleNetworkErrors = e => ({
  [FORM_ERROR]: e.message,
});

export { default as testimonialsDefault } from './responses/testimonials';
export { default as statsDefault } from './responses/stats';
export { default as policyArchiveDefault } from './responses/policy-archive';
export { default as topWritersDefault } from './responses/writers';
export { default as writerDefault } from './responses/writer';
export { default as passwordDefault } from './responses/password';
export { default as approximatePriceDefault } from './responses/approximate-price';
export { default as authDefault } from './responses/auth';
export { default as latestOrdersDefault } from './responses/latest-orders';
export { default as approxPriceDefault } from './responses/approx-price';
