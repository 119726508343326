module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-helmet-canonical-urls@1.4.0_gatsby-plugin-react-helmet@6.13.1_react-helmet@6.1.0/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://essayseek.com","noHash":true,"noQueryString":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Essayseek.com","short_name":"Essayseek.com","start_url":"/","background_color":"#fff","theme_color":"#31aae2","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"64b6f1ad1c8f0d63a7d26eba176324cf"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5NTJZW7","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
