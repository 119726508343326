/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

const { CancelToken } = axios;

interface State {
  response: AxiosResponse<any> | undefined;
  error: any;
  isLoading: boolean;
}

const initialState: State = {
  response: undefined,
  error: undefined,
  isLoading: true,
};

/**
 * XXX added X-Requested-With (server fvt cookie issue)
 */
const useApi = (url: string, config: AxiosRequestConfig = { headers: {} }, run = true) => {
  const [state, setState] = useState<State>(initialState);
  const [mounted, setMounted] = useState(false);

  const configSerialized = JSON.stringify(config);
  const source: CancelTokenSource = CancelToken.source();

  const request = () => {
    axios(url, {
      ...config,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        ...config.headers,
      },
      cancelToken: source.token,
    })
      .then(response => {
        setState({
          error: undefined,
          response,
          isLoading: false,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled: ', error.message);
        } else {
          setState({
            error,
            response: undefined,
            isLoading: false,
          });
        }
      });
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // setState(initialState);

    if (mounted && run) {
      request();
    }

    return () => {
      source.cancel("Request's useEffect cleanup");
    };
  }, [url, configSerialized, mounted]);

  const { response, error, isLoading } = state;

  const setData = (data: any) => {
    setState({ ...state, response: { ...response!, data } });
  };

  const data = response ? response.data : undefined;

  return {
    data,
    response,
    error,
    isLoading,
    setData,
    request,
    source,
  };
};

export default useApi;
