export default {
  orders: [
    {
      order: '123456789',
      rating_writer_comments: 'Loading...',
      rating_customer: '',
      rating_writer: '',
      rating_customer_comments: '',
      finish_dt_date: `Jan 1, ${(new Date()).getFullYear()}`,
      finish_dt_time: '',
      order_title: 'Any topic (writer\'s choice)',
      order_title_private: '',
      discipline_title: 'Discipline title',
      order_pages_qty: '1',
      order_paper_type: 'Paper type',
      writer_pen_name_slug: '',
      writer_pen_name: '',
      writer_rating: '',
      writer_orders_qty: '',
      writer_avatar: '',
      position: 1,
      writer_award_qty: '',
    },
    {
      order: '123456788',
      rating_writer_comments: 'Loading...',
      rating_customer: '',
      rating_writer: '',
      rating_customer_comments: '',
      finish_dt_date: `Jan 1, ${(new Date()).getFullYear()}`,
      finish_dt_time: '',
      order_title: 'Any topic (writer\'s choice)',
      order_title_private: '',
      discipline_title: 'Discipline title',
      order_pages_qty: '1',
      order_paper_type: 'Paper type',
      writer_pen_name_slug: '',
      writer_pen_name: '',
      writer_rating: '',
      writer_orders_qty: '',
      writer_avatar: '',
      position: 1,
      writer_award_qty: '',
    },
    {
      order: '123456787',
      rating_writer_comments: 'Loading...',
      rating_customer: '',
      rating_writer: '',
      rating_customer_comments: '',
      finish_dt_date: `Jan 1, ${(new Date()).getFullYear()}`,
      finish_dt_time: '',
      order_title: 'Any topic (writer\'s choice)',
      order_title_private: '',
      discipline_title: 'Discipline title',
      order_pages_qty: '1',
      order_paper_type: 'Paper type',
      writer_pen_name_slug: '',
      writer_pen_name: '',
      writer_rating: '',
      writer_orders_qty: '',
      writer_avatar: '',
      position: 1,
      writer_award_qty: '',
    },
    {
      order: '123456786',
      rating_writer_comments: 'Loading...',
      rating_customer: '',
      rating_writer: '',
      rating_customer_comments: '',
      finish_dt_date: `Jan 1, ${(new Date()).getFullYear()}`,
      finish_dt_time: '',
      order_title: 'Any topic (writer\'s choice)',
      order_title_private: '',
      discipline_title: 'Discipline title',
      order_pages_qty: '1',
      order_paper_type: 'Paper type',
      writer_pen_name_slug: '',
      writer_pen_name: '',
      writer_rating: '',
      writer_orders_qty: '',
      writer_avatar: '',
      position: 1,
      writer_award_qty: '',
    },
    {
      order: '123456785',
      rating_writer_comments: 'Loading...',
      rating_customer: '',
      rating_writer: '',
      rating_customer_comments: '',
      finish_dt_date: `Jan 1, ${(new Date()).getFullYear()}`,
      finish_dt_time: '',
      order_title: 'Any topic (writer\'s choice)',
      order_title_private: '',
      discipline_title: 'Discipline title',
      order_pages_qty: '1',
      order_paper_type: 'Paper type',
      writer_pen_name_slug: '',
      writer_pen_name: '',
      writer_rating: '',
      writer_orders_qty: '',
      writer_avatar: '',
      position: 1,
      writer_award_qty: '',
    },
  ],
  pagination: [],
};
